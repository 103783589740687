import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import locale from "../../locale/en"
import {
  CalculatorLayout,
  CalculatorInput,
  CalculatorTotal,
  CalculatorButtons,
  CalculatorResultsBox,
  CalculatorGuidelines,
} from "../../components/calculator"
import { sections as faqSections } from "../../components/faq-content/face-shields"
import Fieldset from "../../components/fieldset"
import { useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import logger from "../../lib/logger"
import { generateOnSubmit } from "../../lib/calculator-sections"
const { calculator, faq } = locale
const log = logger.extend("calculator:face-shields")

log(faqSections)

const SECTION_KEY = "face_shields"

const RESET_KEYS = ["face_shields_per_employee", "goggles_per_employee"]

const CalculatorFaceShields = () => {
  const { register, errors, handleSubmit } = useForm()
  const onSubmit = generateOnSubmit(SECTION_KEY)
  const hasErrors = !!Object.keys(errors).length

  if (hasErrors) {
    log("Form errors:", errors)
  }

  return (
    <Layout>
      <SEO title={`${calculator.title} - ${faq.face_shields}`} />
      <CalculatorLayout sectionKey={SECTION_KEY}>
        <h2 className="text-black mb-5">{faq.face_shields}</h2>

        <CalculatorResultsBox>
          <div>
            <CalculatorTotal totalKey="face_shields_per_employee_total" />
            <div className="text-sm">Face shields</div>
          </div>
          <div>
            <CalculatorTotal totalKey="goggles_per_employee_total" />
            <div className="text-sm">Goggles</div>
          </div>
        </CalculatorResultsBox>

        <form className="container mt-10" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex gap-2 mb-5 justify-between">
            <div className="w-1/2">
              <h3 className="text-black mb-4">Inputs</h3>
              <fieldset className="mb-5">
                My business needs, per employee:
              </fieldset>
              <Fieldset
                errorMessage={
                  <ErrorMessage
                    errors={errors}
                    name="face_shields_per_employee"
                  />
                }
              >
                <CalculatorInput
                  type="number"
                  name="face_shields_per_employee"
                  className="w-16 mr-5"
                  register={register({
                    min: {
                      value: 0,
                      message: "Must be 0 or greater",
                    },
                  })}
                />
                <label htmlFor="face_shields_per_employee">face shields</label>
              </Fieldset>
              <Fieldset
                errorMessage={
                  <ErrorMessage errors={errors} name="goggles_per_employee" />
                }
              >
                <CalculatorInput
                  type="number"
                  name="goggles_per_employee"
                  className="w-16 mr-5"
                  register={register({
                    min: {
                      value: 0,
                      message: "Must be 0 or greater",
                    },
                  })}
                />
                <label htmlFor="goggles_per_employee" className="flex-grow">
                  goggles
                </label>
              </Fieldset>
              <CalculatorButtons
                register={register}
                sectionKey={SECTION_KEY}
                resetKeys={RESET_KEYS}
              />
            </div>

            <CalculatorGuidelines sections={faqSections} />
          </div>
        </form>
      </CalculatorLayout>
    </Layout>
  )
}

export default CalculatorFaceShields
