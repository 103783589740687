import React from "react"
import Content from "./content"
import { OutboundLink } from "gatsby-plugin-gtag"

export const sections = [
  {
    heading: "When should I use face shields?",
    body: (
      <>
        <p>
          The primary purpose of face shields is eye protection, and the{" "}
          <OutboundLink href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover-guidance.html">
            CDC
          </OutboundLink>{" "}
          does not currently recommend face shields as substitutes for masks,
          including for children. They may, however, be used instead of a mask
          when masks are not possible – for example, for those who are deaf or
          hard of hearing or who interact with others who are hearing impaired.
        </p>
      </>
    ),
  },
  {
    heading: "How should face shields and goggles be used?",
    body: (
      <>
        <p>
          The{" "}
          <OutboundLink href="https://www.cdc.gov/coronavirus/2019-ncov/community/guidance-manufacturing-workers-employers.html">
            CDC
          </OutboundLink>{" "}
          advises that when used in addition to masks, face shields provide
          extra protection from person-to-person droplet spread, and can help
          minimize contamination of masks. While not necessarily a must-have
          depending on the work environment, they are a great idea in situations
          where close proximity to other employees and customers is difficult to
          avoid, such as in a hair salon or on an assembly line.
        </p>
      </>
    ),
  },
  {
    heading:
      "What should you look for when shopping for face shields and goggles?",
    body: (
      <>
        <p>
          As per the{" "}
          <OutboundLink href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover-guidance.html">
            CDC
          </OutboundLink>
          , face shields should wrap around the sides of the wearer’s face and
          extend below the chin. Hooded face shields, when available, provide
          extra protection. Face shields that attach directly to helmets should
          be worn when helmets are used.
        </p>
      </>
    ),
  },
  {
    heading: "When do I replace my face shields?",
    body: (
      <>
        <p>
          Disposable face shields should only be worn for a single use, and
          reusable face shields should be cleaned and disinfected after each
          shift and stored at the work facility.
        </p>
      </>
    ),
  },
]

const FaceShieldsContent = () => <Content sections={sections} />

export default FaceShieldsContent
